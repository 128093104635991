import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: null,
};

export const userDataSlice = createSlice({
  name: 'userdata',
  initialState,
  reducers: {
    saveData: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { saveData } = userDataSlice.actions;

export default userDataSlice.reducer;