import React, {Suspense} from "react";
import "./App.css";
import {BrowserRouter, Route, Routes} from "react-router-dom";
const loading = (
  <div
    className="d-flex justify-content-center align-items-center "
    style={{height: "100vh"}}
  >
    <img src="loader.jpg" alt="" />
  </div>
);
// user Routes
const UserData = React.lazy(() =>
  import("./userData/component/mainPage/ChatBot")
);
const MainInformation = React.lazy(() =>
  import("./userData/component/mainPage/InformationFill")
);

//Admin Route
const LoginPage = React.lazy(()=>import('./adminData/component/login/LoginPage'));
const Dashboard = React.lazy(()=>import('./adminData/component/dashboard/Dashboard'));
const UserDetails = React.lazy(()=>import('./adminData/component/pages/UsersDetails'));
const QuestionsDetails = React.lazy(()=>import('./adminData/component/pages/QuestionsDetails'));
const AddData = React.lazy(()=>import('./adminData/component/pages/AddData'));

function App() {
  return (
    // basename={"/chatbot"}
    <BrowserRouter  >
      <Suspense fallback={loading}>
        <Routes>
          <Route path="/" element={<MainInformation />} />
          <Route path="/chat" element={<UserData />} />
          <Route  path="/admin" >
           <Route index={true} element={<LoginPage />} />
           <Route path="/admin/dashboard" element={<Dashboard />}></Route>
           <Route path="/admin/users" element={<UserDetails />}></Route>
           <Route path="/admin/ChatMessage" element={<QuestionsDetails />}></Route>
           <Route path="/admin/AddData" element={<AddData />}></Route>
          

          </Route> 
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
